.drawer-content,
.nav-links {
   
    font-size: 21px;
    background: linear-gradient(90deg, rgba(226,107,189,1) 0%, rgba(210,106,198,1) 31%, rgba(117,102,254,1) 79%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 500;
}

.drawer-content a,
.nav-link {
    text-decoration: none !important;
    background: linear-gradient(90deg, rgba(226,107,189,1) 0%, rgba(210,106,198,1) 31%, rgba(117,102,254,1) 79%);
    -webkit-background-clip: text;
    color: transparent;
    margin: 0 15px;
}



.nav_logo {
    max-width: 180px;
    height: auto;
    margin-left: 50px;
    margin-top: 10px;
}



.mobile-icon-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Tablet devices and below (landscape and portrait) */
@media screen and (max-width: 1024px) {
    .drawer-content a,
    .nav-link {
        margin: 0 12px;
    }

    .nav_logo {
        max-width: 160px;
        margin-left: 30px;
    }
}

/* Mobile devices (landscape and portrait) */
@media screen and (max-width: 768px) {
    .drawer-content a,
    .nav-link {
        margin: 0 10px;
        font-size: 14px;
    }

    .nav_logo {
        max-width: 130px;
        margin-left: 20px;
        margin-top:20px;
    }

    .nav-link {
        text-decoration: none !important;
        background: linear-gradient(90deg, rgba(226,107,189,1) 0%, rgba(210,106,198,1) 31%, rgba(117,102,254,1) 79%);
        -webkit-background-clip: text;
        color: transparent;
        margin: auto 10px auto auto;
    }
}

/* Small mobile devices (portrait) */
@media screen and (max-width: 480px) {
    .drawer-content a,
    .nav-link {
        margin: 0 8px;
        font-size: 16px;
    }

    .nav_logo {
        max-width: 150px;
        margin-left: 15px;
        margin-top: -4px;
    }

    .nav-links {
        font-size: 16px;
    }
}

/* Extra small mobile devices (portrait) */
@media screen and (max-width: 360px) {
    .drawer-content a,
    .nav-link {
        margin: 0 5px;
        font-size: 14px;
    }

    .nav_logo {
        max-width: 145px;
        margin-left: 15px;
        margin-top: -4px;
    }

    .nav-links {
        font-size: 14px;
    }
}
/* Extra small mobile devices (portrait) */
@media screen and (max-width: 320px) {
    .drawer-content a,
    .nav-link {
        margin: 0 5px;
        font-size: 16px;
    }

    .nav_logo {
        max-width: 120px;
        margin-left: 15px;
        margin-top: -4px;
    }

    .nav-links {
        font-size: 14px;
    }
}
