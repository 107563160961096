.color,
.spinner {
    border-radius: 50%
}

.difText {
    background: linear-gradient(90deg, #d2122e, #000);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline
}

.mainButton {
    background-color: rgba(1, 0, 0, 0.416);
}

.bannerbg {
    background: linear-gradient(black, #c0112e)
}

.spinner {
    background-color: transparent;
    width: 300px;
    height: 300px;
    position: relative;
    transition: transform 3s ease-in-out
}

.arrow,
.color {
    position: absolute
}

.info-button,
.spin-button {
    transition: background-color .3s
}

.spinner-container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 20px
}

.spinner.spin {
    animation: .5s infinite alternate blink
}

@keyframes blink {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.arrow {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 15px solid #333;
    z-index: 1
}

.color {
    width: 100%;
    height: 50%;
    transform-origin: 50% 100%
}

.info-button,
.read-more-button,
.spin-button {
    border: none;
    cursor: pointer
}

.spin-button {
    padding: 10px 40px;
    background: linear-gradient(90deg, #d2122e, #000);
    color: #fff;
    font-size: 16px;
    margin-top: 60px;
    margin-left: 20px
}

.spin-button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed
}

.spin-button:hover {
    background: linear-gradient(90deg, #841425, #000)
}

.result {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #333
}

.info-button {
    background-color: #f5f5f5;
    color: #000;
    padding: 5px;
    font-size: 16px
}

.disclaimer-content {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    position: relative
}

.disclaimer-text {
    flex: 1
}

.read-more-button {
    background: 0 0;
    padding: 5px 10px;
    color: #0074d9
}

.expanded .read-more-button {
    margin-top: 10px
}

.container-wrapper {
    background-color: #f5f5f5
}

.scroll-text {
    height: 100%;
    text-align: center;
    animation: 5s linear infinite my-animation;
    color: rgba(20, 255, 20, .817);
    font-size: larger;
    font-weight: 700
}

@keyframes my-animation {
    0% {
        transform: translateY(100%)
    }

    100% {
        transform: translateY(-100%)
    }
}

@media screen and (max-width:480px) {
    .spinner {
        width: 200px;
        height: 200px
    }

    .container-wrapper {
        padding: 10px
    }

    .grid {
        grid-template-columns: repeat(1, 1fr)
    }

    .card {
        width: 100%;
        margin-bottom: 20px
    }

    .scroll-button {
        font-size: 14px
    }
}