.DiceGame {
  display: flex;
  flex-flow: column nowrap;
  min-height: 40vh;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.DiceGame-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Die {
  font-size: 10em;
  padding: 0.15em;
  color: #A91D3A;
  transform: rotateY(0deg);
  transition: transform 1s;
}

.Die2 {
  font-size: 10em;
  padding: 0.15em;
  color: #000000;
  transform: rotateY(0deg);
  transition: transform 1s;
}

.Die-shaking {
  animation: wobble 1s infinite;
}

.Die-shaking:nth-child(2) {
  animation-delay: 0.5s;
}

@keyframes wobble {
  0% { transform: translate3d(0, 0, 0) rotateY(0deg); }
  25% { transform: translate3d(-10%, -10%, 0) rotateY(45deg); }
  50% { transform: translate3d(10%, 10%, 0) rotateY(90deg); }
  75% { transform: translate3d(-10%, 10%, 0) rotateY(135deg); }
  100% { transform: translate3d(0, 0, 0) rotateY(180deg); }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .Die, .Die2 {
    font-size: 9em;
  }
}

@media (max-width: 992px) {
  .Die, .Die2 {
    font-size: 8em;
  }
}

@media (max-width: 768px) {
  .Die, .Die2 {
    font-size: 8em;
  }
}

@media (max-width: 576px) {
  .DiceGame {
    padding: 0.5em;
  }

  .Die, .Die2 {
    font-size: 6em;
  }

  .DiceGame-container {
    flex-direction: column;
  }
}
